import LogRocket from 'logrocket';
if (gon && gon.env == 'production') {
  try {
    LogRocket.init('sbsrpa/appgetloopca', {
      network: {
        isEnabled: false,
      },
      release: gon.env,
    });
  } catch (err) {
    console.log(err);
  }
}
