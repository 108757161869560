import amplitude from 'amplitude-js';
import _ from 'lodash';

if (typeof gon !== 'undefined' && typeof gon.amplitude_api_key !== 'undefined') {
  amplitude.getInstance().init(gon.amplitude_api_key);

  $(document).ready(function() {
    $('[data-amplitude-event]').on('click', function() {
      logAmplitudeEvent($(this).data('amplitude-event'));
    });

    $('[log-amplitude-event]').each(function() {
      var eventName = $(this).attr('log-amplitude-event');
      if (eventName) {
        logAmplitudeEvent(eventName);
      }
    });

    if (typeof gon !== 'undefined' && gon.amplitude_event) {
      logAmplitudeEvent(gon.amplitude_event);
    }
  });
}

function logAmplitudeEvent(eventName) {
  if (gon.amplitude_user_id) {
    setAmplitudeUserId(gon.amplitude_user_id);
  }
  const extraData = {};
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.forEach(function(value, key) {
    extraData[key] = value;
  });

  if (_.isEmpty(extraData)) {
    amplitude.getInstance().logEvent(eventName);
  } else {
    amplitude.getInstance().logEvent(eventName, { ...extraData, currentUrl: window.location.href.split('?')[0] });
  }

  clearAmplitudeUser();
}

function setAmplitudeUserId(userId) {
  amplitude.getInstance().setUserId(userId);
}

function clearAmplitudeUser() {
  amplitude.getInstance().setUserId(null);
}
